<template>
    <div class="info">请稍候...</div>
</template>

<script>
    import api from "../../../repo/api";

    export default {
        name: "Detail",
        mounted() {
            this.gotoLink()
        },
        methods: {
            gotoLink() {
                let id = this.$route.params.id
                api.createGoodsUrl({'goods_id': id}, (data) => {

                    if(data.code === 0) location.href = data.data.url
                })
            }
        }
    }
</script>

<style scoped>
    .info {
        height: 100vh;
        width: 100vw;
        text-align: center;
        padding-top: 200px;
    }
</style>